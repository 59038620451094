<template>
  <div>
    <b-spinner
      v-if="loading"
      class="d-block mx-auto my-5"
    />

    <div v-else>
      <div class="payment-amount">
        <div class="font-small-3 font-weight-bold text-uppercase text-muted">
          {{ $t('customer.settlements.payment.amount') }}
        </div>
        <div>
          <span class="font-large-3">{{ getIntegers(totalAmount) }}</span><span
            class="font-large-2 text-muted"
          >,{{ getDecimals(totalAmount) }} zł</span>
        </div>
      </div>

      <div class="payment-method mb-2">
        <div class="mt-1 mb-50 font-small-3 font-weight-bold text-uppercase text-muted">
          {{ $t('customer.settlements.payment.method') }}
        </div>

        <table v-if="!!data.paymentMethod">
          <tr>
            <td>
              <b-img :src="data.paymentMethod.image" />
            </td>
            <td class="font-medium-2">
              <span class="ml-50">
                {{ data.paymentMethod.name }}
              </span>
            </td>
          </tr>
        </table>

        <b-button
          v-if="!!data.paymentMethod"
          variant="flat-primary mt-50"
          @click="showPaymentMethodsModal = true"
        >
          {{ $t('customer.settlements.payment.change-payment-method') }}
        </b-button>
        <b-button
          v-if="!data.paymentMethod"
          variant="primary"
          @click="showPaymentMethodsModal = true"
        >
          {{ $t('customer.settlements.payment.select-payment-method') }}
        </b-button>
      </div>

      <div class="payment-receiver">
        <div class="mt-1 font-small-3 font-weight-bold text-uppercase text-muted">
          {{ $t('customer.settlements.payment.receiver') }}
        </div>
        <div class="font-medium-5">
          {{ organization.name }}
        </div>
      </div>

      <div
        v-if="me.type === type.guardian"
        class="payment-student"
      >
        <div class="mt-1 font-small-3 font-weight-bold text-uppercase text-muted">
          {{ $t('customer.settlements.payment.student') }}
        </div>
        <div class="font-medium-5">
          {{ student.lastName }} {{ student.firstName }}
        </div>
      </div>

      <div class="payment-items">
        <div class="mt-2 font-small-3 font-weight-bold text-uppercase text-muted">
          {{ $t('customer.settlements.payment.meetings') }}
        </div>
        <div class="mt-50 event-list">
          <b-alert
            v-if="settlementsToPay.length === 0"
            show
            class="p-1"
          >
            <feather-icon icon="InfoIcon" />
            {{ $t('customer.settlements.payment.all-meetings-paid') }}
          </b-alert>
          <div
            v-for="settlement in settlementsToPay"
            :key="settlement.id"
            class="event-item card cursor-pointer p-1 mb-50"
            :class="{'muted': !settlement.checked, 'border-primary': !!settlement.hovered}"
            @click="handleCalendarEventClick(settlement)"
            @mouseover="handleCalendarEventMouseover(settlement)"
            @mouseout="clearSelection()"
          >
            <div
              v-if="settlement.type === 'NegativeBalance'"
              class="d-flex align-items-center justify-content-between"
            >
              <div>
                <feather-icon
                  :icon="settlement.checked ? 'CheckSquareIcon' : 'SquareIcon'"
                  size="18"
                  class="mr-50"
                />
                <span>
                  {{ $t('customer.settlements.payment.negative-balance') }}
                  <rc-help :description="$t('customer.settlements.payment.negative-balance-description')" />
                </span>
              </div>
              <div class="text-right">
                <b-badge variant="light-danger">
                  {{ getIntegers(settlement.amount) }},{{ getDecimals(settlement.amount) }} zł
                </b-badge>
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-between"
            >
              <span>
                <feather-icon
                  :icon="settlement.checked ? 'CheckSquareIcon' : 'SquareIcon'"
                  size="18"
                  class="mr-50"
                />
                <span v-if="settlement.service">
                  {{ settlement.service.name }}
                </span>
              </span>
              <div class="text-right">
                <b-badge :variant="settlement.isOverdue ? 'light-danger' : 'light-warning'">
                  {{ getIntegers(settlement.amount) }},{{ getDecimals(settlement.amount) }} zł
                </b-badge>
                <div
                  v-if="settlement.servicePriceType && settlement.servicePriceType !== 'PerMeeting'"
                  class="text-nowrap font-small-2"
                >
                  {{ $t(`shared.price-types.${settlement.servicePriceType}`).toLowerCase() }}
                </div>
              </div>
            </div>
            <span
              v-for="calendarEventCustomer in settlement.calendarEventCustomers"
              :key="`calendar-event-customer-${calendarEventCustomer.id}`"
              class="d-inline-block font-small-2 ml-2 pl-50 mt-25"
            >
              <feather-icon
                icon="CalendarIcon"
                size="12"
                class="mr-25 mt-n25"
              />{{ transformDatetimeToHumanReadablePretty(calendarEventCustomer.calendarEvent.startAt) }}
            </span>
          </div>
        </div>

        <div class="additional-amount">
          <div
            :class="{'muted': !additionalAmountIsHovering, 'border-primary': !!additionalAmountIsHovering}"
            class="event-item card cursor-pointer p-1 mb-50"
            @click="handleCalendarEventClick('additional-amount'); $refs.additionalAmountInput.focus();"
            @mouseover="handleCalendarEventMouseover('additional-amount'); additionalAmountIsHovering = true;"
            @mouseout="clearSelection(); additionalAmountIsHovering = false;"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span>
                <feather-icon
                  :icon="additionalAmount > 0 ? 'CheckSquareIcon' : 'SquareIcon'"
                  size="18"
                  class="mr-50"
                />
                <span>
                  {{ $t('customer.settlements.payment.additional-amount') }}
                </span>
              </span>
              <div>
                <input
                  ref="additionalAmountInput"
                  v-model="additionalAmount"
                  type="number"
                  class="text-right font-medium-4"
                  onfocus="this.select()"
                >
                zł
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-button
        class="mt-3 btn-block"
        variant="primary"
        size="lg"
        :disabled="!isValid"
        @click="processPayment"
      >
        {{ $t('customer.settlements.payment.pay') }}
        {{ getIntegers(totalAmount) }},{{ getDecimals(totalAmount) }} zł
      </b-button>
    </div>

    <b-modal
      v-model="showPaymentMethodsModal"
      :title="$t('customer.settlements.payment.select-payment-method')"
      hide-footer
    >
      <app-collapse accordion>
        <app-collapse-item
          v-for="(integration, key) in paymentIntegrations"
          :key="integration.integrationId"
          :title="integration.integrationName"
          :is-visible="key === 0"
          class="box-shadow-0"
        >
          <b-row
            class="match-height"
            align-v="center"
          >
            <b-col
              v-for="(paymentMethod, paymentMethodKey) in integration.methods"
              :key="paymentMethodKey"
              cols="6"
              lg="4"
              class="cursor-pointer payment-method-box"
            >
              <div
                class="text-center mx-n50 mb-1 border rounded"
                :class="{'active border-primary shadow': paymentMethod === data.paymentMethod}"
                @click="setPaymentMethod(integration.integrationId, paymentMethod)"
              >
                <b-aspect
                  aspect="21:9"
                >
                  <div class="d-flex">
                    <b-img :src="paymentMethod.image" />
                  </div>
                </b-aspect>
                <div
                  class="text-nowrap mx-50 mb-50 overflow-hidden text-primary"
                  style="text-overflow: ellipsis"
                >
                  <small>{{ paymentMethod.name }}</small>
                </div>
              </div>

            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-modal>

  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import paymentSetup from '@/views/_global/payments/paymentSetup'
import RcHelp from '@/layouts/components/rc/RcHelp.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    RcHelp,
  },
  directives: {
    Ripple,
  },
  setup: paymentSetup,
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/variables';
@import '~@core/scss/base/components/variables-dark';

.payment-method-box {
  img {
    max-width: 60%;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .6;
    transition: opacity .2s;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

.additional-amount {
  input {
    border: none;
    background: transparent;
    color:#6e6b7b;
    width: 60px;
    &:focus-visible {
      outline: none;
    }
  }
}

.event-list, .additional-amount{
  .event-item {
    transition: all 0.4s;
    border: 1px solid transparent;
  }
}
</style>
